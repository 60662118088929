import React from 'react';
import { translate } from '@avensia/scope';
import { StyledProps, styled } from '@glitz/react';
import Button, { Appearance } from 'Shared/Button';
import FulfilledIcon from 'Shared/Icon/Check';
import { ItemType, Top, Bottom, Base, UpsellAll } from './Item';
import PriceViewModel from 'Pricing/PriceViewModel.type';
import { pixelsToUnit, lightGrey, minMediumMediaQuery } from 'Shared/Style';

export type ProductType = {
  code: string;
  ticket: string;
  price: PriceViewModel;
};

type PropType = ItemType & {
  selected: boolean;
  onSelect: (product: ProductType) => void;
} & StyledProps;
export default styled((props: PropType) => {
  const {
    hasPromotion,
    hasDiscount: productHasDiscount,
    isNew,
    isUpsellModal,
    averageRating,
    imageUrls,
    displayName,
    price,
    brand,
  } = props;
  const { current, original, currency } = price;
  const hasDiscount = current < original;

  const topProps = {
    hasPromotion,
    productHasDiscount,
    isNew,
    averageRating,
    imageUrl: imageUrls.length ? imageUrls[0] : '',
    displayName,
    currency,
    price,
    original,
    upsell: price.upsell,
  };

  const bottomProps = {
    brand,
    currency: price.currency,
    displayName,
    productHasDiscount,
    hasDiscount,
    current,
    original,
    upsell: price.upsell,
    currentPrice: price.upsell ? price.upsell : price.current,
  };

  const upsellAllProps = {
    hasPromotion,
    productHasDiscount,
    isNew,
    isUpsellModal,
    averageRating,
    imageUrl: imageUrls.length ? imageUrls[0] : '',
    displayName,
    currency,
    price,
    original,
    upsell: price.upsell,
    brand,
    hasDiscount,
    current,
    currentPrice: price.upsell ? price.upsell : price.current,
  };

  const buttonApperance = props.selected ? [Appearance.Upsell, Appearance.Full] : [Appearance.Primary, Appearance.Full];

  function toggleSelect() {
    props.onSelect({ code: props.variantCode, ticket: props.ticket, price: props.price });
  }

  const checkBoxSelect = (
    <CheckBox appearance={buttonApperance} onClick={toggleSelect}>
      {props.selected ? <FulfilledIcon /> : translate('/Upsell/Choose')}
    </CheckBox>
  );

  const checkBoxSelectModal = (
    <CheckBoxModal appearance={buttonApperance} onClick={toggleSelect}>
      {props.selected ? <FulfilledIcon /> : translate('/Upsell/Choose')}
    </CheckBoxModal>
  );

  return (
    <React.Fragment>
      {isUpsellModal ? (
        <ContentUpsellModal>
          <UpsellAll {...upsellAllProps} />
          <ButtonWrapper>{checkBoxSelectModal}</ButtonWrapper>
        </ContentUpsellModal>
      ) : (
        <Base css={props.compose()}>
          <Content>
            <Top {...topProps} />
            <Bottom {...bottomProps} />
          </Content>
          {checkBoxSelect}
        </Base>
      )}
    </React.Fragment>
  );
});

const Content = styled.div({
  color: theme => theme.textColor,
  width: '100%',
});

const ContentUpsellModal = styled.div({
  display: 'flex',
  color: theme => theme.textColor,
  width: '100%',
  borderBottom: {
    color: lightGrey,
    style: 'solid',
    width: pixelsToUnit(1),
  },
});

const CheckBox = styled(Button, {
  lineHeight: 2.25,
});

const CheckBoxModal = styled(Button, {
  width: '4rem',
  height: '4rem',
  borderRadius: '100%',
  alignSelf: 'center',
  [minMediumMediaQuery]: {
    width: '5rem',
    height: '5rem',
  },
});

const ButtonWrapper = styled.div({
  width: '20%',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
});
