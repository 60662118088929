import React, { ReactNode } from 'react';
import { styled } from '@glitz/react';
import SlideIndexMarkers from './SlideIndexMarkers';
import { Carousel, getAbsoluteIndex, AnimationStyle, Navigation, Direction } from './';
import ElementDimensions from './ElementDimensions';
import * as style from 'Shared/Style';

type BasePropType = {
  height?: string;
  children: ReactNode;
};

const Base = ({ height, children }: BasePropType) => (
  <styled.Div
    css={{
      position: 'relative',
      height: height || 'auto',
    }}
  >
    {children}
  </styled.Div>
);

const CarouselBase = styled.div({
  overflow: 'hidden',
});

const ButtonContainer = styled.div({
  position: 'absolute',
  width: '100%',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: style.ZIndex.SiteBanner,
  pointerEvents: 'none',
  display: 'flex',
  justifyContent: 'space-between',
});

type PropType = {
  isHero?: boolean;
  continuous?: boolean;
  disabled?: boolean;
  autoSlideTime?: number;
  hideNavigationButtons?: boolean;
  hideIndexMarkers?: boolean;
  currentSlide?: number;
  onSlideChange?: (index: number) => void;
  height?: string;
  backgroundColor?: string; //We could have used a glitz´ styles for this, but this is easier to follow
  slidesPerPage?: number;
  navigationOffset?: boolean;
};

type StateType = {
  currentSlide: number;
};

export default class CommonCarousel extends React.Component<PropType, StateType> {
  static defaultProps = {
    slidesPerPage: 1,
  };

  constructor(props: PropType) {
    super(props);

    const children = React.Children.toArray(this.props.children);
    this.itemCount = children.length;
    this.state = {
      currentSlide: this.props.currentSlide ? this.props.currentSlide : 0,
    };
  }

  itemCount: number;

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  }

  componentWillReceiveProps(nextProps: PropType) {
    const children = React.Children.toArray(this.props.children);
    this.itemCount = children.length;
    if (nextProps.currentSlide !== this.props.currentSlide) {
      this.setState({
        currentSlide: nextProps.currentSlide ? nextProps.currentSlide : 0,
      });
    }
  }

  goToSlide = (index: number) => {
    if (!this.props.continuous) {
      index = Math.min(index, this.itemCount - this.props.slidesPerPage);
      index = Math.max(index, 0);
    }
    this.setState({ currentSlide: index });
    this.props.onSlideChange && this.props.onSlideChange(getAbsoluteIndex(index, this.itemCount));
  };

  handleKeyPress = (e: KeyboardEvent) => {
    if (e.keyCode === 37) {
      this.goPrev();
    } else if (e.keyCode === 39) {
      this.goNext();
    }
  };

  goPrev = () => {
    this.goToSlide(this.state.currentSlide - this.props.slidesPerPage);
  };
  goNext = () => {
    this.goToSlide(this.state.currentSlide + this.props.slidesPerPage);
  };
  onSlideIndexClick = (relativeIndex: number) => {
    this.goToSlide(this.state.currentSlide + relativeIndex);
  };
  render() {
    const { slidesPerPage } = this.props;
    const children = React.Children.toArray(this.props.children);
    const nbrOfItems = children.length > slidesPerPage ? children.length : slidesPerPage;
    const isFirstSlide = this.state.currentSlide === 0;
    const isLastSlide = this.state.currentSlide === nbrOfItems - slidesPerPage;

    return (
      <Base height={this.props.height}>
        <CarouselBase>
          <ElementDimensions>
            {(dimensionsProp) => (
              <Carousel
                elementRef={dimensionsProp.elementRef}
                carouselWidth={dimensionsProp.dimensions.width}
                slidesPerPage={slidesPerPage}
                height={this.props.height}
                backgroundColor={this.props.backgroundColor}
                disabled={this.props.disabled}
                currentSlide={this.state.currentSlide}
                onSlideChange={(index: number) => {
                  this.setState({ currentSlide: index });
                  this.props.onSlideChange && this.props.onSlideChange(getAbsoluteIndex(index, this.itemCount));
                }}
                continuous={this.props.continuous}
                autoSlideTime={this.props.autoSlideTime}
                animationStyle={AnimationStyle.Gentle}
              >
                {children}
              </Carousel>
            )}
          </ElementDimensions>
        </CarouselBase>
        {!this.props.hideNavigationButtons && !this.props.disabled && children.length > 1 && (
          <ButtonContainer>
            {this.props.continuous || !isFirstSlide ? (
              <Navigation
                onClick={this.goPrev}
                direction={Direction.Prev}
                disabled={false}
                offset={this.props.navigationOffset}
              />
            ) : (
              <Navigation
                onClick={() => {
                  return false;
                }}
                direction={Direction.Prev}
                disabled={true}
                offset={this.props.navigationOffset}
              />
            )}
            {this.props.continuous || !isLastSlide ? (
              <Navigation
                disabled={false}
                onClick={this.goNext}
                direction={Direction.Next}
                offset={this.props.navigationOffset}
              />
            ) : (
              <Navigation
                disabled={true}
                onClick={() => {
                  return false;
                }}
                direction={Direction.Next}
                offset={this.props.navigationOffset}
              />
            )}
          </ButtonContainer>
        )}
        {!this.props.hideIndexMarkers && !this.props.disabled && children.length > 1 && (
          <SlideIndexMarkers
            nbrOfSlides={nbrOfItems}
            activeSlideIndex={getAbsoluteIndex(this.state.currentSlide, children.length)}
            onSlideIndexClick={this.onSlideIndexClick}
          />
        )}
      </Base>
    );
  }
}
