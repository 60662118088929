import React from 'react';
import { URLX, pathCombine, postJson, replaceState, translate } from '@avensia/scope';
import { epiPropertyValue } from '@avensia/scope-episerver';
import { Style } from '@glitz/type';
import { styled } from '@glitz/react';
import EsalesProductForList from '../../../../Esales/Models/EsalesProductForList.type';
import {
  pixelsToUnit,
  thin,
  huge,
  theta,
  beta,
  carnation,
  minSmallMediaQuery,
  transition,
  white,
  wildwatermelon,
  lightGrey,
} from 'Shared/Style';
import Viewport from 'Shared/Viewport';
import ProductsPanel from 'Shared/ProductsPanel';
import {
  connectWithFeedback,
  Behavior as ButtonBehavior,
  Appearance as ButtonAppearance,
  ConnectPropType as FeedbackPropType,
} from 'Shared/Button/Feedback';
import { formatPrice } from 'Shared/number-format';
import connect from 'Shared/connect';
import Product, { ProductType } from 'Checkout/ProductsPanel/ItemUpsell';
import { getProduct as getPanelProduct } from 'Checkout/ProductsPanel/utils';
import { completeUpsellPurchase } from 'Checkout/action-creators';
import Cover from 'Checkout/Pages/Checkout/Cover';
import { Section, Header as Title } from './OrderConfirmationPage';
import OrderConfirmationType from './OrderConfirmationPageViewModel.type';
import { format } from '@avensia/scope';
import Panel from 'Shared/Panel';
import Icon from 'Shared/Icon/Close';

enum PriceType {
  Current = 'current',
  Original = 'original',
  Upsell = 'upsell',
}

type TimerType = {
  availableUntilTimestamp?: number;
  currentServerTimestamp?: number;
};

type ConnectStateType = {
  currency: string;
  culture: string;
};

type ConnectActionType = {
  upsellOrderComplete: (orderConfirmation: OrderConfirmationType) => void;
};

type PropType = {
  title: string;
  subTitle: string;
  addText: string;
  introText: string;
  confirmText: string;
  timeIsUpText: string;
  failedText: string;
  orderGroupId: string;
  requestUrl: string;
  upsellProducts: EsalesProductForList[];
} & FeedbackPropType &
  ConnectStateType &
  ConnectActionType &
  TimerType;

type StateType = {
  orderProducts: ProductType[];
  lockMode: boolean;
  timeIsUp: boolean;
  upsellFail: boolean;
  upsellViewAllModalOpen: boolean;
  timeLeft: number;
};

function getProductsPriceSum(products: ProductType[], mode: PriceType) {
  if (products.length) {
    return products.reduce((accum, currVal) => accum + currVal.price[mode], 0);
  } else {
    return 0;
  }
}

function formatTime(num: number) {
  function twoDigits(n: number) {
    return n <= 9 ? `0${n}` : String(n);
  }

  if (num >= 60) {
    // tslint:disable-next-line:no-bitwise
    return `${twoDigits((num / 60) | 0)}:${twoDigits(num % 60 | 0)}`;
  } else {
    return `00:${twoDigits(num)}`;
  }
}

function getTimeLeft(availableUntilTimestamp: number, timeOffset: number) {
  return Math.floor((new Date(availableUntilTimestamp * 1000).getTime() - Date.now() + timeOffset) / 1000);
}
class UpsellProducts extends React.Component<PropType, StateType> {
  panelRef = React.createRef<HTMLDivElement>();
  timer: number;
  timeOffset = Date.now() - new Date(this.props.currentServerTimestamp * 1000).getTime();
  constructor(props: PropType) {
    super(props);
    this.state = {
      orderProducts: [],
      lockMode: false,
      timeIsUp: false,
      upsellFail: false,
      upsellViewAllModalOpen: false,
      timeLeft: getTimeLeft(this.props.availableUntilTimestamp, this.timeOffset),
    };
  }

  componentDidUpdate(prevProps: PropType, prevState: StateType) {
    if (!prevState.upsellFail && this.state.upsellFail) {
      setTimeout(() => {
        this.setState(() => ({ upsellFail: false }));
      }, FAILED_TEXT_VISIBLE_DELAY);
    }
  }

  componentDidMount() {
    if (this.state.timeLeft) {
      this.startTimer();
    }
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer() {
    this.timer = setInterval(() => {
      const timeLeft = getTimeLeft(this.props.availableUntilTimestamp, this.timeOffset);
      if (timeLeft) {
        this.setState({ timeLeft });
      } else {
        this.stopTimer();
        this.timerTimeIsUp();
      }
    }, 1000);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  updateOrderProducts = (selectedProduct: ProductType) => {
    this.setState((prevState) => {
      const selected = this.isSelected(prevState.orderProducts, selectedProduct.code);
      return {
        orderProducts: selected
          ? prevState.orderProducts.filter((product) => product.code !== selectedProduct.code)
          : [...prevState.orderProducts, selectedProduct],
      };
    });
  };

  isSelected(products: ProductType[], code: string) {
    return Boolean(products.find((product) => product.code === code));
  }

  getTotalPrice() {
    return getProductsPriceSum(this.state.orderProducts, PriceType.Upsell);
  }

  getTotalUpsellSavings() {
    return getProductsPriceSum(this.state.orderProducts, PriceType.Original) - this.getTotalPrice();
  }

  getAddText() {
    const { addText, currency, culture } = this.props;
    return format(addText, {
      count: this.state.orderProducts.length,
      total: formatPrice(this.getTotalPrice(), currency, culture),
      savings: formatPrice(this.getTotalUpsellSavings(), currency, culture),
    });
  }

  timerTimeIsUp = () => this.setState(() => ({ timeIsUp: true }));

  toggleUpsellViewAllModal = () =>
    this.setState(() => ({ upsellViewAllModalOpen: !this.state.upsellViewAllModalOpen }));

  confirmOrder = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    e.preventDefault();
    this.props.feedback.push(this.sendRequest());
  };

  sendRequest = async () => {
    const { orderProducts } = this.state;
    if (orderProducts.length) {
      this.setState(() => ({ lockMode: true }));

      const codes = orderProducts.map((product) => product.code);
      const tickets = orderProducts.map((product) => product.ticket);

      const url = new URLX(this.props.requestUrl);
      url.pathname = pathCombine(url.pathname, 'add');

      const query = {
        id: this.props.orderGroupId,
        codes,
        tickets,
      };

      try {
        await postJson(url, query);
        replaceState(null, { includeAppShellData: true });
      } catch (error) {
        console.error('Upsell sendRequest error:', error.status);
        this.setState(() => ({ upsellFail: true, lockMode: false }));
        return Promise.reject(null);
      }
    }
  };

  render() {
    const { orderProducts } = this.state;
    const { upsellProducts, confirmText, introText, title, subTitle } = this.props;
    const FeedbackButton = this.props.feedback.Button;
    const feedbackButtonStyle: Style = {
      fontSize: theta,
      letterSpacing: pixelsToUnit(0.75),
      margin: {
        x: 'auto',
      },
      padding: {
        x: huge,
      },
      [minSmallMediaQuery]: {
        margin: {
          left: 'auto',
          right: 0,
        },
      },
    };
    const upsellModalCardStyle: Style = {
      width: '100%',
      height: '10rem',
    };

    const timer = (
      <styled.Div
        css={this.state.timeLeft > ONE_MINUTE ? { fontWeight: 'bold' } : { fontWeight: 'bold', color: wildwatermelon }}
      >
        {`${formatTime(this.state.timeLeft)} ${
          this.state.timeLeft >= ONE_MINUTE
            ? translate('/CountdownBanner/Minutes')
            : this.state.timeLeft < ONE_MINUTE
            ? translate('/CountdownBanner/Seconds')
            : translate('/CountdownBanner/Second')
        }`}
      </styled.Div>
    );

    return (
      <Section>
        {this.state.timeIsUp ? (
          <TimesUpBody>{this.props.timeIsUpText}</TimesUpBody>
        ) : (
          <React.Fragment>
            <Title>{title}</Title>
            <FailedText
              css={{
                opacity: this.state.upsellFail ? 1 : 0,
              }}
            >
              {this.props.failedText}
            </FailedText>
            <SubTitle
              css={{
                opacity: this.state.upsellFail ? 0 : 1,
              }}
            >
              {subTitle.substring(0, subTitle.indexOf('{'))}
              <styled.Div css={{ fontSize: pixelsToUnit(20), margin: { xy: pixelsToUnit(10) } }}>{timer}</styled.Div>
              <OpenModalButton onClick={this.toggleUpsellViewAllModal}>
                {translate('/Upsell/ShowYourPersonalOffers')}
              </OpenModalButton>
            </SubTitle>
            <Body>
              <React.Fragment>
                <Panel isOpen={this.state.upsellViewAllModalOpen} onClose={this.toggleUpsellViewAllModal}>
                  <TimeMessage>
                    <styled.Div>{translate('/Upsell/PersonalOffersExpiresIn')}</styled.Div>
                    <styled.Div css={{ fontSize: pixelsToUnit(30) }}>{timer}</styled.Div>
                  </TimeMessage>

                  <Close onClick={this.toggleUpsellViewAllModal}>
                    <Icon />
                  </Close>
                  <UpsellViewAllModalContent>
                    {upsellProducts.map((product) => {
                      const code = epiPropertyValue(product.variation.code);
                      return (
                        <Product
                          key={code}
                          {...getPanelProduct(product)}
                          selected={this.isSelected(orderProducts, code)}
                          onSelect={this.updateOrderProducts}
                          css={upsellModalCardStyle}
                          isUpsellModal={true}
                        />
                      );
                    })}
                  </UpsellViewAllModalContent>
                  <ConfirmSection>
                    <Text>{!!orderProducts.length ? this.getAddText() : introText}</Text>
                    <FeedbackButton
                      css={feedbackButtonStyle}
                      appearance={ButtonAppearance.Primary}
                      disabled={!orderProducts.length}
                      onClick={this.confirmOrder}
                    >
                      {confirmText}
                    </FeedbackButton>
                  </ConfirmSection>
                </Panel>
              </React.Fragment>
              <Viewport>
                {(isCompact: boolean) => (
                  <ProductsPanel
                    slideQuantityToShow={isCompact ? 2 : 4}
                    navigationOffsetWidth={CARD_WIDTH * (isCompact ? MOBILE_CARDS : DESKTOP_CARDS)}
                  >
                    {upsellProducts.map((product) => {
                      const code = epiPropertyValue(product.variation.code);
                      return (
                        <Product
                          key={code}
                          {...getPanelProduct(product)}
                          selected={this.isSelected(orderProducts, code)}
                          onSelect={this.updateOrderProducts}
                        />
                      );
                    })}
                  </ProductsPanel>
                )}
              </Viewport>
              <ConfirmSection>
                <Text>{!!orderProducts.length ? this.getAddText() : introText}</Text>
                <FeedbackButton
                  css={feedbackButtonStyle}
                  appearance={ButtonAppearance.Primary}
                  disabled={!orderProducts.length}
                  onClick={this.confirmOrder}
                >
                  {confirmText}
                </FeedbackButton>
              </ConfirmSection>
            </Body>
          </React.Fragment>
        )}
        {this.state.lockMode && <Cover visibleElement={null} />}
      </Section>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    currency: state.appShellData.cart.currency,
    culture: state.appShellData.culture,
  }),
  (dispatch): ConnectActionType => ({
    upsellOrderComplete: (orderConfirmation: OrderConfirmationType) => {
      return dispatch(completeUpsellPurchase(orderConfirmation));
    },
  }),
)(connectWithFeedback({ behavior: ButtonBehavior.KeepEnabled })(UpsellProducts));

const CARD_WIDTH = 132;
const DESKTOP_CARDS = 5;
const MOBILE_CARDS = 1;
const FAILED_TEXT_VISIBLE_DELAY = 2500;
const ONE_MINUTE = 60;

const textStyle = {
  fontSize: theta,
  lineHeight: pixelsToUnit(22),
  letterSpacing: pixelsToUnit(0.3),
};

const SubTitle = styled.div({
  padding: {
    y: pixelsToUnit(30),
    x: pixelsToUnit(10),
  },
  textAlign: 'center',
  ...textStyle,
  ...transition({ property: 'opacity ' }),
});

const Body = styled.div({
  backgroundColor: 'rgba(243, 244, 251, 0.4)',
  padding: {
    xy: pixelsToUnit(30),
  },
});

const ConfirmSection = styled.div({
  padding: { x: 0, y: pixelsToUnit(20) },
  margin: { x: pixelsToUnit(15) },
  textAlign: 'center',
  border: {
    top: {
      width: thin,
      style: 'solid',
      color: (theme) => theme.borderColor,
    },
  },
  [minSmallMediaQuery]: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right',
  },
});

const Text = styled.div({
  marginBottom: pixelsToUnit(20),
  textAlign: 'center',
  ...textStyle,
  [minSmallMediaQuery]: {
    maxWidth: pixelsToUnit(490),
    margin: 0,
    textAlign: 'left',
  },
});

const TimesUpBody = styled(Body, {
  fontSize: beta,
  lineHeight: 1.5,
  textAlign: 'center',
});

const FailedText = styled(SubTitle, {
  color: carnation,
  position: 'absolute',
  width: '100%',
});

const Close = styled.div({
  position: 'absolute',
  right: pixelsToUnit(15),
  top: pixelsToUnit(15),
  bottom: pixelsToUnit(15),
});

const UpsellViewAllModalContent = styled.div({
  overflowY: 'auto',
  padding: { x: '1rem', y: 0 },
});

const OpenModalButton = styled.div({
  backgroundColor: wildwatermelon,
  fontWeight: 'bold',
  color: white,
  borderRadius: pixelsToUnit(5),
  padding: { xy: pixelsToUnit(5) },
  marginTop: pixelsToUnit(5),
  cursor: 'pointer',
  ':hover': { textDecoration: 'underline' },
});

const TimeMessage = styled.div({
  textAlign: 'center',
  padding: { xy: pixelsToUnit(15) },
  border: {
    bottom: {
      width: thin,
      style: 'solid',
      color: lightGrey,
    },
  },
});
