import React from 'react';
import { styled } from '@glitz/react';
import { pseudo } from '@glitz/core';
import Button, { Variant as ButtonVariant, Appearance as ButtonAppearance } from 'Shared/Button';
import { gamma, delison, silver, scorpion } from 'Shared/Style';

const Entry = styled(Button, {
  borderRadius: '50%',
  width: gamma,
  height: gamma,
  ...pseudo(':not(:last-child)', {
    marginRight: '9px',
  }),
});

const Svg = styled.svg({
  width: delison,
  height: delison,
});
const Circle = styled.circle({
  transition: {
    property: 'opacity',
    duration: '0.5s',
  },
});

type PropType = {
  isCurrent: boolean;
  onClick: () => void;
};

const SIZE = 100;
const CENTER = SIZE / 2;
const BULLET_RADIUS = SIZE * 0.425;

const Bullet = (props: PropType) => (
  <Entry variant={ButtonVariant.None} appearance={ButtonAppearance.Bare} onClick={props.onClick}>
    <Svg viewBox={`0 0 ${SIZE} ${SIZE}`}>
      <g>
        <Circle r={BULLET_RADIUS} cy={CENTER} cx={CENTER} fill={silver} opacity={1} />
        <Circle r={BULLET_RADIUS} cy={CENTER} cx={CENTER} fill={scorpion} opacity={props.isCurrent ? 1 : 0} />
      </g>
    </Svg>
  </Entry>
);

export default Bullet;
