import React, { ReactNode } from 'react';

type State = {
  dimensions: Dimensions;
};
type Props = {
  children?: (args: RenderArgs) => ReactNode;
  onDimensionChange?: (dimensions: Dimensions) => void;
};
type Dimensions = {
  width: number;
  height: number;
  left: number;
  right: number;
  top: number;
  bottom: number;
};
type RenderArgs = {
  dimensions: Dimensions;
  elementRef: (el: HTMLElement) => void;
};

export default class ElementDimensions extends React.PureComponent<Props, State> {
  measureElement: HTMLElement;
  mounted = false;
  unsubscribeResize = () => {};

  constructor(props: Props) {
    super(props);
    this.state = {
      dimensions: {
        width: null,
        height: null,
        left: null,
        right: null,
        top: null,
        bottom: null,
      },
    };
  }
  getElementDimensions() {
    return !!this.measureElement ? this.measureElement.getBoundingClientRect() : this.state.dimensions;
  }
  componentDidMount() {
    const initialDimensions = this.getElementDimensions();
    this.onDimensionChange(initialDimensions);
    this.setState({ dimensions: initialDimensions });

    window.addEventListener('resize', () => {
      const dimensions = this.getElementDimensions();
      if (this.state.dimensions !== dimensions) {
        this.onDimensionChange(dimensions);
        this.setState({ dimensions });
      }
    });
  }
  componentWillUnmount() {
    this.unsubscribeResize();
  }
  elementRef = (el: HTMLElement) => {
    this.measureElement = el;
  };
  onDimensionChange = (dimensions: Dimensions) => {
    this.props.onDimensionChange && this.props.onDimensionChange(dimensions);
  };
  render() {
    return this.props.children({ dimensions: this.state.dimensions, elementRef: this.elementRef });
  }
}
