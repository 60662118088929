/**
 * @ComponentFor OrderConfirmationPageViewModel
 */
import React from 'react';
import { styled } from '@glitz/react';
import { pseudo } from '@glitz/core';
import connect from 'Shared/connect';
import { translate, loadScript, currentUrl, Breakpoint } from '@avensia/scope';
import { epiPropertyValue } from '@avensia/scope-episerver';
import {
  theta,
  sigma,
  gamma,
  epsilon,
  iota,
  tundora,
  zeta,
  mountainmeadow,
  minMicroMediaQuery,
  minSmallMediaQuery,
  pixelsToUnit,
  alpha,
  omega,
  whitelilac,
  small,
} from 'Shared/Style';
import Image, { Ratio as ImageRatio } from 'Shared/Image/Ratio';
import { Main, Appearance } from 'Shared/PageLayout';
import Price from 'Pricing/Price';
import OrderConfirmationPageViewModelType from './OrderConfirmationPageViewModel.type';
import UpsellProducts from './UpsellProducts';

type StateType = {
  orderValue: number;
  programID: number;
};

type ConnectStateType = { currentBreakpoint: number };

type PropType = OrderConfirmationPageViewModelType & ConnectStateType;

export const Section = styled.section({
  color: (theme) => theme.textColor,
  maxWidth: pixelsToUnit(800),
  margin: {
    x: 'auto',
  },
  padding: {
    y: pixelsToUnit(20),
  },
  position: 'relative',
  width: '100%',
});

export const Header = styled.header({
  color: (theme) => theme.textColor,
  fontSize: omega,
  lineHeight: pixelsToUnit(42),
  textAlign: 'center',
  [minSmallMediaQuery]: {
    fontSize: alpha,
  },
});

const OrderDetails = styled.div({
  maxWidth: pixelsToUnit(500),
  margin: {
    x: 'auto',
  },
  textAlign: 'center',
  fontSize: theta,
  lineHeight: 1.47,
  padding: {
    y: pixelsToUnit(60),
  },
});

const OrderDetailsText = styled.span({
  display: 'block',
  marginBottom: pixelsToUnit(20),
  ':last-of-type': {
    marginBottom: 0,
  },
  ...pseudo(':nth-child(3)', {
    marginBottom: 0,
  }),
});

const ProductDetails = styled.div({
  marginBottom: pixelsToUnit(60),
  backgroundColor: whitelilac,
});

const ProductRow = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: {
    xy: pixelsToUnit(6),
  },
  [minSmallMediaQuery]: {
    padding: {
      xy: small,
    },
  },
});

const ProductImage = styled.div({
  flex: {
    grow: 1,
    shrink: 1,
    basis: pixelsToUnit(190),
  },
  mixBlendMode: 'multiply',
  [minSmallMediaQuery]: {
    marginRight: pixelsToUnit(15),
  },
});

const ProductDescription = styled.div({
  display: 'flex',
  width: '100%',
});

const ProductDescriptionMobile = styled(ProductDescription, {
  padding: {
    xy: small,
  },
  flex: {
    grow: 1,
    shrink: 1,
    basis: pixelsToUnit(900),
  },
});

const ProductDescriptionHeader = styled(ProductDescription, {
  fontSize: zeta,
  textTransform: 'uppercase',
});

const ProductTitle = styled.div({
  alignSelf: 'center',
  fontSize: sigma,
  color: tundora,
  lineHeight: 'normal',
  flex: {
    grow: 1,
    shrink: 1,
  },
});

const Quantity = styled.div({
  fontWeight: 'bold',
  textAlign: 'center',
  alignSelf: 'center',
  width: '30%',
});

const QuantityHeader = styled(Quantity, {
  fontSize: zeta,
  fontWeight: 'normal',
  textTransform: 'uppercase',
  width: '30%',
});

const PaymentDetails = styled.div({
  maxWidth: pixelsToUnit(800),
  margin: {
    x: 'auto',
    bottom: pixelsToUnit(130),
  },
});

const PaymentDetailsRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  color: tundora,
});

const PaymentLabel = styled.div({
  fontSize: theta,
  lineHeight: 2,
});

const StyledPrice = styled(Price, {
  fontWeight: 'bold',
});

const UnitPriceHeader = styled.div({
  fontSize: zeta,
  textTransform: 'uppercase',
  textAlign: 'center',
  width: '30%',
});

const UnitPrice = styled(StyledPrice, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: epsilon,
  textAlign: 'center',
  width: '30%',
});

const TotalUnitsPriceHeader = styled(UnitPriceHeader, {
  textAlign: 'center',
  width: '30%',
});

const TotalUnitsPrice = styled(UnitPrice, {
  textAlign: 'center',
  width: '30%',
});

const BasePrice = styled(StyledPrice, {
  fontSize: gamma,
  lineHeight: pixelsToUnit(30),
});

const TotalPrice = styled(BasePrice, {
  fontSize: iota,
  color: mountainmeadow,
});

const PriceWrapperMobile = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});

const ProductRowsWrapperMobile = styled.div({
  alignSelf: 'center',
  flexBasis: '35rem',
  [minMicroMediaQuery]: {
    flexBasis: pixelsToUnit(750),
  },
});

const Wrapper = styled.div({
  borderBottom: {
    style: 'solid',
    color: (theme) => theme.borderColor,
    width: '1px',
  },
  paddingBottom: pixelsToUnit(20),
});

const InnerWrapper = styled.div({
  display: 'flex',
});

class OrderconfirmationPage extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      orderValue: this.setOrderValue(),
      programID: this.setProgramID(),
    };
  }

  componentDidMount() {
    const { orderNumber, discountCodes, billingAddress } = this.props.orderDetails;
    const trackIDCookie = this.getCookieData('trackID');
    const trackID: number = trackIDCookie.length > 0 ? parseInt(trackIDCookie, 10) : null;
    const discountedCodes = discountCodes || '';

    const adRecordData = {
      ...this.state,
      orderNumber,
      couponCode: discountedCodes,
      trackID,
    };

    this.appendAdRecordScript(adRecordData);

    if (!!window.tp) {
      const trustpilot_invitation = {
        recipientEmail: billingAddress.email,
        recipientName: billingAddress.firstName,
        referenceId: orderNumber,
        source: 'InvitationScript',
      };

      window.tp('createInvitation', trustpilot_invitation);
    }
  }
  setOrderValue() {
    return this.props.orderDetails.orderLineItems.reduce((sum, lineItem) => sum + lineItem.price, 0);
  }
  setProgramID() {
    const hostName = currentUrl().hostname;
    const isDoggieSite = hostName.indexOf('doggie') !== -1;
    let domainID = 0;

    if (isDoggieSite) {
      domainID = 242;
    } else {
      const DOMAIN_NAME = 2;
      const domainName = hostName.split('.')[DOMAIN_NAME].toLowerCase();

      switch (domainName) {
        case 'se':
          domainID = 241;
          break;

        case 'fi':
          domainID = 779;
          break;

        case 'no':
          domainID = 780;
          break;

        case 'dk':
          domainID = 781;
          break;

        default:
          break;
      }
    }
    return domainID;
  }

  // This script is from adrecord
  getCookieData(t: string) {
    for (let n = document.cookie.split('; '), r = 0; r < n.length; r++) {
      const p = n[r].split('=');

      if (p[0] === t) {
        return p[1];
      }
    }
    return '';
  }

  appendAdRecordScript(data: any) {
    window.adrecord = {
      programID: data.programID,
      orderID: data.orderNumber,
      orderValue: data.orderValue,
      couponCode: data.couponCode,
      trackID: data.trackID,
    };

    loadScript('https://track.adrecord.com/track.js');
  }

  render() {
    const isMobile = this.props.currentBreakpoint < Breakpoint.Medium;
    const { orderDetails } = this.props;
    const {
      orderNumber,
      paymentMethodName,
      paymentType,
      shippingMethodName,
      orderLineItems,
      currency,
      paymentFee,
      totalPrice,
      shippingFee,
    } = orderDetails;
    const productImageSize = '&w=100&h=100';
    const tradedoublerIFrame = this.props.tradedoublerViewModel ? (
      <iframe
        src={
          `https://tbs.tradedoubler.com/report?organization=` +
          `${this.props.tradedoublerViewModel.organization}` +
          `&event=${this.props.tradedoublerViewModel.event}` +
          `&orderNumber=${this.props.tradedoublerViewModel.orderNumber}` +
          `&orderValue=${this.props.tradedoublerViewModel.orderValue}` +
          `&currency=${this.props.tradedoublerViewModel.currency}` +
          `&tduid=${this.props.tradedoublerViewModel.tduid}` +
          `&type=iframe` +
          `${this.props.tradedoublerViewModel.voucher ? `&voucher=${this.props.tradedoublerViewModel.voucher}` : ``}` +
          `&reportInfo=${this.props.tradedoublerViewModel.reportInfo}`
        }
        frameBorder={0}
        width={1}
        height={1}
        loading="lazy"
      ></iframe>
    ) : null;

    return (
      <Main appearance={Appearance.Narrow}>
        {tradedoublerIFrame}

        {!!(this.props.upsellProducts || []).length && (
          <UpsellProducts
            title={epiPropertyValue(this.props.page.upsellTitle)}
            subTitle={epiPropertyValue(this.props.page.upsellSubTitle)}
            addText={epiPropertyValue(this.props.page.upsellAddText)}
            introText={epiPropertyValue(this.props.page.upsellIntroText)}
            confirmText={epiPropertyValue(this.props.page.upsellConfirmText)}
            timeIsUpText={epiPropertyValue(this.props.page.upsellTimeIsUpText)}
            failedText={epiPropertyValue(this.props.page.upsellFailedText)}
            availableUntilTimestamp={this.props.upsellAvailableUntilTimestamp}
            currentServerTimestamp={this.props.currentServerTimestamp}
            orderGroupId={this.props.upsellOrderGroupId}
            upsellProducts={this.props.upsellProducts}
            requestUrl={this.props.url}
          />
        )}

        <Section>
          <Header>{translate('/OrderConfirmation/Heading')}</Header>
          <OrderDetails>
            <OrderDetailsText>{`${translate('/OrderConfirmation/OrderNumber')} : ${orderNumber}`}</OrderDetailsText>
            <OrderDetailsText>
              {`${translate('/OrderConfirmation/ConfirmationMessage')}: ${
                this.props.trackingInformationTransaction.emailAddress
              }`}
            </OrderDetailsText>
            <OrderDetailsText>
              {`${translate('/OrderConfirmation/PaymentMethod')}: `}
              {paymentMethodName}
              {paymentType ? ` - ${paymentType}` : ''}
            </OrderDetailsText>
            <OrderDetailsText>{`${translate(
              '/OrderConfirmation/ShippingMethod',
            )}: ${shippingMethodName}`}</OrderDetailsText>
            {this.props.orderDetails.instaboxDeliveryPoint ? (
              <OrderDetailsText>{this.props.orderDetails.instaboxDeliveryPoint}</OrderDetailsText>
            ) : null}
          </OrderDetails>
          <ProductDetails>
            {!isMobile && (
              <ProductRow>
                <ProductImage />
                <ProductDescriptionHeader>{translate('/OrderConfirmation/Product')}</ProductDescriptionHeader>
                <QuantityHeader>{translate('/OrderConfirmation/Amount')}</QuantityHeader>
                <UnitPriceHeader>{translate('/OrderConfirmation/PriceEach')}</UnitPriceHeader>
                <TotalUnitsPriceHeader>{translate('/OrderConfirmation/Price')}</TotalUnitsPriceHeader>
              </ProductRow>
            )}
            {orderLineItems.map((order) =>
              isMobile ? (
                <Wrapper>
                  <ProductImage />
                  <ProductDescriptionMobile>
                    <ProductTitle>{order.productName}</ProductTitle>
                  </ProductDescriptionMobile>
                  <InnerWrapper>
                    <ProductImage css={{ marginLeft: '1rem' }}>
                      <Image
                        src={`${order.imageUrl}${productImageSize}`}
                        title={order.productName}
                        alt={order.productName}
                        ratio={ImageRatio.OneToOne}
                      />
                    </ProductImage>
                    <ProductRowsWrapperMobile>
                      <ProductRow>
                        <QuantityHeader>{translate('/OrderConfirmation/Amount')}</QuantityHeader>
                        <UnitPriceHeader>{translate('/OrderConfirmation/PriceEach')}</UnitPriceHeader>
                        <TotalUnitsPriceHeader>{translate('/OrderConfirmation/Price')}</TotalUnitsPriceHeader>
                      </ProductRow>
                      <ProductRow key={order.productUrl}>
                        <PriceWrapperMobile>
                          <Quantity>{order.quantity}</Quantity>
                          <UnitPrice current={order.listPrice} currency={currency} />
                          <TotalUnitsPrice current={order.price} currency={currency} />
                        </PriceWrapperMobile>
                      </ProductRow>
                    </ProductRowsWrapperMobile>
                  </InnerWrapper>
                </Wrapper>
              ) : (
                <ProductRow key={order.productUrl}>
                  <ProductImage>
                    <Image
                      src={`${order.imageUrl}${productImageSize}`}
                      title={order.productName}
                      alt={order.productName}
                      ratio={ImageRatio.OneToOne}
                    />
                  </ProductImage>
                  <ProductDescription>
                    <ProductTitle>{order.productName}</ProductTitle>
                  </ProductDescription>
                  <Quantity>{order.quantity}</Quantity>
                  <UnitPrice current={order.listPrice} currency={currency} />
                  <TotalUnitsPrice current={order.price} currency={currency} />
                </ProductRow>
              ),
            )}
          </ProductDetails>
          <PaymentDetails>
            <PaymentDetailsRow>
              <PaymentLabel>{translate('/OrderConfirmation/ShippingLabel')}</PaymentLabel>
              <BasePrice current={shippingFee} currency={currency} />
            </PaymentDetailsRow>
            <PaymentDetailsRow>
              <PaymentLabel>{translate('/OrderConfirmation/PaymentLabel')}</PaymentLabel>
              <BasePrice current={paymentFee} currency={currency} />
            </PaymentDetailsRow>
            <PaymentDetailsRow>
              <PaymentLabel css={{ fontWeight: 'bold' }}>{translate('/OrderConfirmation/PaymentTotal')}</PaymentLabel>
              <TotalPrice current={totalPrice} currency={currency} />
            </PaymentDetailsRow>
          </PaymentDetails>
        </Section>
      </Main>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    currentBreakpoint: state.currentBreakpoint,
  }),
)(OrderconfirmationPage);
