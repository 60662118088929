import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { pseudo } from '@glitz/core';
import { translate, isIE } from '@avensia/scope';
import Price, { Type as PriceType } from 'Pricing/Price';
import Image, { Ratio, Preset } from 'Shared/Image/Ratio';
import {
  iota,
  gamma,
  epsilon,
  zeta,
  pixelsToUnit,
  whitelilac,
  minTinyMediaQuery,
  theta,
  kappa,
  wildwatermelon,
  white,
  minMicroMediaQuery,
  black,
} from 'Shared/Style';
import connect from 'Shared/connect';
import Button, { Appearance, Variant } from 'Shared/Button';
import { formatTitleCase } from 'Shared/string-format';
import Link from 'Shared/Link';
import AddToCart from 'Product/ProductAddToCart';
import AverageRating, { Spacing, Size } from 'Product/ProductDetails/AverageRating';
import { CampaignBadge, DiscountBadge, NewBadge } from 'Product/ProductCard/Badge';
import PriceViewModel from 'Pricing/PriceViewModel.type';
import { addCartItem } from 'Checkout/action-creators';
import CartItemViewModel from 'Cart/Models/CartItemViewModel.type';

type ConnectStateType = {
  items: CartItemViewModel[];
};

type ConnectActionType = {
  addToCart: (code: string, quantity: number, ticket: string) => any;
};

export type ItemType = {
  brand: string;
  averageRating: number;
  ticket: string;
  price: PriceViewModel;
  displayName: string;
  variantCode: string;
  imageUrls: string[];
  isNew: boolean;
  inStock: boolean;
  isUpsellModal?: boolean;
  hasPromotion: boolean;
  hasDiscount: boolean;
  url: string;
  hasMultipleVariants: boolean;
};

type PropType = {
  product: ItemType;
} & ConnectStateType &
  ConnectActionType &
  StyledProps;

class Item extends React.Component<PropType> {
  addToCart = () => {
    const code = this.props.product.variantCode;
    const existingItem = this.props.items.find((i) => i.code === code && !i.isGift);
    const quantity = existingItem ? existingItem.quantity + 1 : 1;

    return this.props.addToCart(code, quantity, this.props.product.ticket);
  };

  render() {
    const {
      hasPromotion,
      hasDiscount: productHasDiscount,
      isNew,
      averageRating,
      imageUrls,
      displayName,
      price,
      brand,
      url,
      ticket,
      isUpsellModal,
      hasMultipleVariants,
    } = this.props.product;
    const { current, original } = price;
    const hasDiscount = current < original;

    const topProps = {
      hasPromotion,
      productHasDiscount,
      isNew,
      isUpsellModal,
      averageRating,
      imageUrl: imageUrls.length ? imageUrls[0] : '',
      displayName,
      price,
      currency: price.currency,
      current,
      original,
      currentPrice: price.current,
      upsell: price.upsell,
    };

    const bottomProps = {
      brand,
      currency: price.currency,
      displayName,
      productHasDiscount,
      hasDiscount,
      current,
      original,
      currentPrice: price.current,
      upsell: price.upsell,
    };

    const button = hasMultipleVariants ? (
      <Button
        css={{ lineHeight: pixelsToUnit(36), whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        to={url}
        variant={Variant.None}
        appearance={[Appearance.Primary, Appearance.Full]}
      >
        <Text>{translate('/ProductListing/ViewProduct')}</Text>
      </Button>
    ) : (
      <AddToCart
        css={{ lineHeight: pixelsToUnit(36) }}
        variant={Variant.None}
        disabled={!this.props.product.inStock}
        addToCart={this.addToCart}
      >
        <Text>{this.props.product.inStock ? translate('/Cart/BuyMobile') : translate('/Cart/OutOfStrock')}</Text>
      </AddToCart>
    );

    return (
      <Base css={this.props.compose()}>
        <ContentLink to={url} linkIdentifier={ticket}>
          <Top {...topProps} />
          <Bottom {...bottomProps} />
        </ContentLink>
        {button}
      </Base>
    );
  }
}

type TopPropType = {
  hasPromotion: boolean;
  productHasDiscount: boolean;
  isNew: boolean;
  currency: string;
  averageRating: number;
  imageUrl: string;
  displayName: string;
  price: PriceViewModel;
  original: number;
  upsell: number;
};

export function Top(props: TopPropType) {
  return (
    <TopBase>
      {props.hasPromotion && (
        <CampaignBadge css={isIE() && { backgroundImage: 'none' }}>
          {translate('/Product/Badge/Campaign')}
        </CampaignBadge>
      )}
      {props.productHasDiscount && !props.upsell ? (
        <DiscountBadge>{`-${props.price.discountPercentage}%`}</DiscountBadge>
      ) : (
        props.isNew && <NewBadge>{`${translate('/Product/Badge/New')}!`}</NewBadge>
      )}
      <Image
        src={props.imageUrl}
        alt={props.displayName}
        itemProp="image"
        preset={!props.imageUrl.includes('.gif') ? Preset.Tiny : undefined}
        ratio={Ratio.OneToOne}
        css={{ height: 'auto', width: '100%' }}
      />
      <RatingsPlaceholder>
        {!!props.averageRating && <Ratings average={props.averageRating} spacing={Spacing.Normal} size={Size.Tiny} />}
      </RatingsPlaceholder>
    </TopBase>
  );
}

type BottomPropType = {
  brand: string;
  currency: string;
  displayName: string;
  productHasDiscount: boolean;
  hasDiscount: boolean;
  current: number;
  original: number;
  currentPrice: number;
  upsell: number;
};

export function Bottom(props: BottomPropType) {
  const discount = props.original - props.currentPrice;
  return (
    <React.Fragment>
      <Title title={props.displayName} itemProp="name">{`${props.brand || ''} ${formatTitleCase(
        props.displayName,
      )}`}</Title>
      <Prices>
        <Price
          css={discount > 0 ? { fontSize: iota, color: wildwatermelon } : { fontSize: iota, color: black }}
          current={props.hasDiscount && !props.upsell ? Math.round(props.current) : Math.round(props.currentPrice)}
          currency={props.currency}
          priceType={props.hasDiscount ? PriceType.New : PriceType.Regular}
        />
        {props.productHasDiscount && (
          <Price
            css={{
              fontSize: zeta,
              marginLeft: pixelsToUnit(5),
            }}
            current={props.original}
            currency={props.currency}
            priceType={PriceType.Old}
          />
        )}
        {props.upsell > 0 && !props.productHasDiscount && props.currentPrice !== props.original && (
          <Price
            css={{
              fontSize: zeta,
              marginLeft: pixelsToUnit(5),
            }}
            current={props.original}
            currency={props.currency}
            priceType={PriceType.Old}
          />
        )}
      </Prices>
      <SaveUpsellTextContainer>
        {props.currentPrice !== props.original && (
          <SaveUpsellText>
            {translate('/Upsell/Save') + ' '}
            {Math.round(discount)}
            {translate('/Upsell/Currency')}
          </SaveUpsellText>
        )}
      </SaveUpsellTextContainer>
    </React.Fragment>
  );
}

type UpsellAllPropType = {
  hasPromotion: boolean;
  productHasDiscount: boolean;
  isNew: boolean;
  currency: string;
  averageRating: number;
  imageUrl: string;
  displayName: string;
  price: PriceViewModel;
  original: number;
  upsell: number;
  isUpsellModal: boolean;
  brand: string;
  hasDiscount: boolean;
  current: number;
  currentPrice: number;
};

export function UpsellAll(props: UpsellAllPropType) {
  const discount = props.original - props.upsell;
  return (
    <UpsellModalItemBase>
      {props.hasPromotion && (
        <CampaignBadge css={isIE() && { backgroundImage: 'none' }}>
          {translate('/Product/Badge/Campaign')}
        </CampaignBadge>
      )}
      {props.productHasDiscount && !props.upsell ? (
        <DiscountBadge>{`-${props.price.discountPercentage}%`}</DiscountBadge>
      ) : (
        props.isNew && <NewBadge>{`${translate('/Product/Badge/New')}!`}</NewBadge>
      )}
      <Wrapper>
        <ImagePriceWrapper>
          <ImageModal
            src={props.imageUrl}
            alt={props.displayName}
            itemProp="image"
            preset={!props.imageUrl.includes('.gif') ? Preset.Tiny : undefined}
            ratio={Ratio.OneToOne}
          />
          <styled.Div css={{ display: 'flex', alignItems: 'center' }}>
            <TitlePriceWrapper>
              <RatingsPlaceholder css={{ marginTop: pixelsToUnit(10) }}>
                <Ratings average={props.averageRating} spacing={Spacing.Normal} size={Size.Tiny} />
              </RatingsPlaceholder>
              <TitleModal title={props.displayName} itemProp="name">{`${props.brand || ''} ${formatTitleCase(
                props.displayName,
              )}`}</TitleModal>
              <Prices css={{ paddingBottom: 0 }}>
                <Price
                  css={props.upsell ? { fontSize: iota, color: wildwatermelon } : { fontSize: epsilon }}
                  current={props.hasDiscount && !props.upsell ? props.current : props.currentPrice}
                  currency={props.currency}
                  priceType={props.hasDiscount ? PriceType.New : PriceType.Regular}
                />
                {props.productHasDiscount && (
                  <Price
                    css={{
                      fontSize: zeta,
                      marginLeft: pixelsToUnit(5),
                    }}
                    current={props.original}
                    currency={props.currency}
                    priceType={PriceType.Old}
                  />
                )}
                {props.upsell > 0 && !props.productHasDiscount && (
                  <Price
                    css={{
                      fontSize: zeta,
                      marginLeft: pixelsToUnit(5),
                    }}
                    current={props.original}
                    currency={props.currency}
                    priceType={PriceType.Old}
                  />
                )}
              </Prices>
              <TinySaveText>
                {translate('/Upsell/Save')} {discount}
                {translate('/Upsell/Currency')}
              </TinySaveText>
            </TitlePriceWrapper>
          </styled.Div>
        </ImagePriceWrapper>
        <Arrow>
          {translate('/Upsell/Save')} {discount}
          {translate('/Upsell/Currency')}
        </Arrow>
      </Wrapper>
    </UpsellModalItemBase>
  );
}

export default styled(
  connect(
    (state): ConnectStateType => ({
      items: state.cart.items,
    }),
    (dispatch): ConnectActionType => ({
      addToCart(code: string, quantity: number, ticket: string) {
        return dispatch(addCartItem(code, quantity, ticket, 'Kassakampanjer'));
      },
    }),
  )(Item),
);

export const Base = styled.div({
  backgroundColor: whitelilac,
  display: 'flex',
  flexFlow: 'column nowrap',
  flex: {
    grow: 0,
    shrink: 0,
    basis: pixelsToUnit(132),
  },
  height: '100%',
  padding: {
    xy: pixelsToUnit(2),
  },
  margin: { xy: '0.2rem' },
  textAlign: 'center',
  ...pseudo(':not(:first-child)', {
    marginLeft: pixelsToUnit(5),
  }),
});

const ContentLink = styled(Link, {
  color: (theme) => theme.textColor,
  display: 'flex',
  flexFlow: 'column nowrap',
});

const TopBase = styled.div({});

const UpsellModalItemBase = styled.div({
  width: '100%',
  position: 'relative',
});

const RatingsPlaceholder = styled.div({
  height: pixelsToUnit(13),
});

const Ratings = styled(AverageRating, {
  height: pixelsToUnit(13),
  width: '100%',
  zIndex: 1,
});

const Title = styled.div({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: zeta,
  fontWeight: 'bold',
  maxHeight: pixelsToUnit(66),
  padding: {
    top: pixelsToUnit(15),
    x: pixelsToUnit(10),
    bottom: 0,
  },
  wordBreak: 'break-word',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  lineHeight: pixelsToUnit(18),
});

const TitleModal = styled(Title, {
  whiteSpace: 'normal',
  fontWeight: 'bold',
  padding: {
    top: pixelsToUnit(5),
    x: pixelsToUnit(0),
    bottom: 0,
  },
});

const Prices = styled.div({
  flex: {
    grow: 0,
    shrink: 0,
    basis: pixelsToUnit(47),
  },
  fontSize: gamma,
  fontWeight: 'bold',
  maxHeight: pixelsToUnit(47),
  padding: {
    top: pixelsToUnit(8),
    bottom: pixelsToUnit(8),
    x: 0,
  },
});

const Text = styled.span({
  fontSize: kappa,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  letterSpacing: pixelsToUnit(0.8),
  lineHeight: pixelsToUnit(36),
  [minMicroMediaQuery]: {
    fontSize: theta,
  },
});

const SaveUpsellText = styled.div({
  backgroundColor: wildwatermelon,
  color: white,
  borderRadius: pixelsToUnit(5),
  padding: { xy: pixelsToUnit(2) },
  fontWeight: 'bold',
  height: pixelsToUnit(30),
});

const SaveUpsellTextContainer = styled.div({
  height: pixelsToUnit(30),
  marginBottom: pixelsToUnit(5),
});

const ImagePriceWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const TitlePriceWrapper = styled.div({
  marginLeft: pixelsToUnit(15),
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
});

const Arrow = styled.div({
  color: white,
  fontWeight: 'bold',
  width: '9rem',
  height: '5rem',
  minWidth: '9rem',
  backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,75,106,1) 35%, rgba(255,75,106,1) 100%)',
  clipPath: 'polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)',
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  [minTinyMediaQuery]: {
    display: 'flex',
  },
});

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '8rem',
});

const ImageModal = styled(Image, {
  height: '4rem',
  width: '4rem',
  minWidth: '4rem',
  [minMicroMediaQuery]: {
    height: '6rem',
    width: '6rem',
    minWidth: '6rem',
  },
});

const TinySaveText = styled(SaveUpsellText, {
  width: '7rem',
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: pixelsToUnit(10),
  [minTinyMediaQuery]: {
    display: 'none',
  },
});
