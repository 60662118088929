import React from 'react';
import { styled, StyledElementProps } from '@glitz/react';
import { CommonCarousel } from 'Shared/Carousel';

type PropType = {
  prev?: () => void;
  next?: () => void;
  children: React.ReactNode;
  slideQuantityToShow: number;
  navigationOffsetWidth: number;
} & StyledElementProps;

class ProductsPanel extends React.Component<PropType> {
  render() {
    return (
      <Base>
        <CommonCarousel
          navigationOffset={true}
          hideIndexMarkers
          slidesPerPage={this.props.slideQuantityToShow}
          height={'100%'}
        >
          {this.props.children}
        </CommonCarousel>
      </Base>
    );
  }
}

export default ProductsPanel;

const Base = styled.div({
  position: 'relative',
});
