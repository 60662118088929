import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import * as style from 'Shared/Style';
import { media } from '@glitz/core';

type PropType = React.HTMLAttributes<HTMLDivElement> & {
  nbrOfSlides: number;
  activeSlideIndex: number;
  onSlideIndexClick: (relativeIndex: number) => void;
} & StyledProps;

const SlideContainer = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
});

const SlideContainerActive = styled(SlideContainer, {
  position: 'absolute',
  left: 0,
  right: 0,
  zIndex: 1,
  pointerEvents: 'none',
});

const SlideIndex = styled(
  ({ isActive, compose, ...restProps }: { isActive: boolean } & StyledProps & React.HTMLAttributes<HTMLDivElement>) => (
    <styled.Div
      css={compose({
        margin: { x: style.pixelsToUnit(5) },
        width: style.pixelsToUnit(16),
        height: style.pixelsToUnit(16),
        border: { y: { x: { radius: '50%' } } },
        backgroundColor: style.lightGrey,
        cursor: 'pointer',
        opacity: 1,
        boxShadow: '0 0 0 1px rgba(255,255,255,1)',
        ...style.transition({ property: 'opacity' }),
        ...(isActive && {
          opacity: 0,
          cursor: 'default',
        }),
      })}
      {...restProps}
    />
  ),
);

const SlideIndexActive = styled(
  ({ isActive, compose, ...restProps }: { isActive: boolean } & StyledProps & React.HTMLAttributes<HTMLDivElement>) => (
    <SlideIndex
      css={compose({
        backgroundColor: theme => theme.primaryColor,
        opacity: 0,
        transform: 'scale(0.25)',
        ...style.transition({ property: ['opacity', 'transform'], duration: '0.5s' }),
        ...(isActive && {
          opacity: 1,
          transform: 'scale(1)',
        }),
      })}
      {...restProps}
      isActive={isActive}
    />
  ),
);

class SlideIndexMarkers extends React.PureComponent<PropType> {
  onSlideIndexClick = (index: number) => {
    const relativeIndex = index - this.props.activeSlideIndex;
    this.props.onSlideIndexClick(relativeIndex);
  };
  render() {
    return (
      <styled.Div className={this.props.className} css={this.props.compose()}>
        <SlideContainer>
          {[...Array(this.props.nbrOfSlides).fill(1)].map((x, i) => (
            <SlideIndex
              key={i}
              isActive={this.props.activeSlideIndex === i}
              onClick={() => this.onSlideIndexClick(i)}
            />
          ))}
          <SlideContainerActive>
            {[...Array(this.props.nbrOfSlides).fill(1)].map((x, i) => (
              <SlideIndexActive
                key={i}
                isActive={this.props.activeSlideIndex === i}
                onClick={() => this.onSlideIndexClick(i)}
              />
            ))}
          </SlideContainerActive>
        </SlideContainer>
      </styled.Div>
    );
  }
}

export default styled(SlideIndexMarkers, {
  position: 'absolute',
  zIndex: style.ZIndex.Tray,
  bottom: style.pixelsToUnit(8),
  left: 0,
  right: 0,
  width: '100%',
  ...media('print', {
    display: 'none',
  }),
});
