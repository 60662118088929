import React from 'react';
import { Motion, spring, OpaqueConfig, presets } from 'react-motion';
import { Swipeable } from 'react-swipeable';
import { styled, applyClassName } from '@glitz/react';

export enum AnimationStyle {
  Default,
  Gentle,
}

export enum Variant {
  Variation,
  HtmlBlock,
}

type Props = {
  currentSlide: number;
  onSlideChange?: (index: number) => void;
  className?: string;
  elementRef: (element: HTMLElement) => void;
  carouselWidth: number;
  slidesPerPage: number;
  autoSlideTime?: number;
  continuous?: boolean;
  disabled?: boolean;
  animationStyle?: AnimationStyle;
  children?: React.ReactNode;
  variant?: Variant;
  center?: boolean;
  height?: string;
  backgroundColor?: string;
  assumedImageWidth?: number;
  onSwipeRest?: () => void;
  onSlideClick?: (index: number) => void;
};

type State = {
  translateX: number;
  currentSlide: number;
  items: React.ReactNode[];
};

type PlainStyleType<TValue> = {
  translateX: TValue;
};

const Container = styled.div({
  display: 'block',
  whiteSpace: 'nowrap',
});

const DELTA = 3;
const SWIPE_THRESHOLD = 25;

class Carousel extends React.PureComponent<Props, State> {
  autoSlideTimer: number;
  slideWidth: number;
  slideWidthPercent: number;
  itemCountUnique: number;
  itemCountCloned: number;
  isResting: boolean;
  container: Element;
  lastDeltaX = 0;
  lastDir = 0;
  offsetDeltaX = 0;
  slideWillChange = 0;

  static defaultProps = {
    slidesPerPage: 1,
  };

  constructor(props: Props) {
    super(props);

    this.slideWidth = props.carouselWidth / props.slidesPerPage;
    this.slideWidthPercent = 100 / props.slidesPerPage;

    let children = React.Children.toArray(this.props.children);
    const items = this.props.continuous ? children.concat(children) : children;
    this.itemCountUnique = children.length;
    this.itemCountCloned = items.length;

    this.isResting = true;
    this.itemCount = children.length;

    this.state = {
      translateX: 0,
      items,
      currentSlide: this.props.currentSlide ? this.props.currentSlide : 0,
    };
  }

  itemCount: number;

  componentDidMount() {
    this.setState({
      translateX: this.getTranslateX(this.props.currentSlide),
    });
    this.setUpAutoSlide();
  }

  componentWillUnmount() {
    clearInterval(this.autoSlideTimer);
  }

  setUpAutoSlide() {
    const { autoSlideTime, disabled } = this.props;
    if (
      !disabled &&
      this.state.items.length > 1 &&
      typeof autoSlideTime === 'number' &&
      autoSlideTime > 0 &&
      document.hidden !== undefined
    ) {
      this.autoSlideTimer && clearInterval(this.autoSlideTimer);
      this.autoSlideTimer = setInterval(() => {
        this.goForward(1);
      }, autoSlideTime);

      //Prevent autosliding when browser window is hidden
      document.addEventListener(
        'visibilitychange',
        () => {
          if (document.hidden) {
            clearInterval(this.autoSlideTimer);
          } else {
            this.setUpAutoSlide();
          }
        },
        false,
      );
    }
  }

  getTranslateX(currentSlide: number) {
    return !this.props.disabled ? currentSlide * -this.slideWidth : 0;
  }

  componentWillReceiveProps(nextProps: Props) {
    const hasNewSlideIndex = nextProps.currentSlide !== this.state.currentSlide;
    const hasNewCarouselWidth = nextProps.carouselWidth !== this.props.carouselWidth;
    const hasNewSlideWidth = nextProps.slidesPerPage !== this.props.slidesPerPage;
    const hasNewSlidesAdded =
      React.Children.toArray(nextProps.children).length !== React.Children.toArray(this.props.children).length;

    if (hasNewCarouselWidth || hasNewSlideWidth) {
      this.slideWidth = nextProps.carouselWidth / nextProps.slidesPerPage;
      this.slideWidthPercent = 100 / nextProps.slidesPerPage;
    }
    if (hasNewSlideIndex) {
      this.setState({
        currentSlide: nextProps.currentSlide,
        translateX: this.getTranslateX(nextProps.currentSlide),
      });
    }
    if (hasNewSlideIndex || hasNewCarouselWidth || hasNewSlideWidth) {
      if (!hasNewSlidesAdded && !hasNewCarouselWidth && !hasNewSlideWidth) {
        this.isResting = false;
      }
      this.setUpAutoSlide();
      this.setState({ translateX: this.getTranslateX(nextProps.currentSlide) });
    }
    if (hasNewSlidesAdded) {
      const children = React.Children.toArray(nextProps.children);
      //Duplicate the slides if the carousel should be able to loop:
      const items = nextProps.continuous ? children.concat(children) : children;

      this.itemCountUnique = children.length;
      this.itemCountCloned = items.length;

      this.isResting = true;

      this.setState({
        items,
        translateX: this.getTranslateX(nextProps.currentSlide),
      });
    }
  }

  /**
   *  To enable looping and to have enough items to break out of page container we duplicate all items in array.
   *  When we navigate in the carousel we are moving the container first and foremost.
   *  Let's say we have X unique items.
   *  Since we support looping, we will run out of slides to show when we are have navigated X*2 times.
   *  To overcome this we are moving all individual items X*2 * 100 percent at this stage. No it's looping!
   *  We also want to have slides visible at the right hand side all the time so when we have stepped X times we will move
   *  the X first slides to the right so that our two duplicate halves of slides will rotate.
   *  Since we want to have our start slide aligned with the left hand side of the page container and items are
   *  flowing right we need slides to move infront of the first slide. We handle this with slideShouldMoveToFront method.
   */
  slideShouldMoveToFront(index: number) {
    // We visibly move items from the end of the non-active half to before the active half.
    const itemCountToMoveToFront = this.itemCountUnique > 2 ? 2 : 1;
    const isFirstHalf = this.isActiveSlideInFirstHalf();
    const activeSlideIsInFront =
      getAbsoluteIndex(this.state.currentSlide, this.itemCountUnique) < itemCountToMoveToFront;
    const slideIsInOtherHalf =
      (isFirstHalf && index >= this.itemCountUnique) || (!isFirstHalf && index < this.itemCountUnique);
    const indexOffsetFromEnd = (isFirstHalf ? this.itemCountCloned : this.itemCountUnique) - 1 - index;
    return activeSlideIsInFront && slideIsInOtherHalf && indexOffsetFromEnd < itemCountToMoveToFront;
  }
  getSlideTransformPercent(slideIndex: number) {
    if (!this.props.continuous) {
      return 0;
    }

    // When all slides have passed, move them all forward so index point on first item. Enables looping.
    const itemCountClonedLoopCount = Math.floor(this.state.currentSlide / this.itemCountCloned);
    let steps = itemCountClonedLoopCount * this.itemCountCloned;

    /*  When the half of them items has passed move it back so that our two halfs will rotate being "active".
        Balances out the slides so that we have slides in viewport all the time. */
    const thisSlidIsInFirstHalf = slideIndex < this.itemCountUnique;
    if (!this.isActiveSlideInFirstHalf() && thisSlidIsInFirstHalf) {
      steps += this.itemCountCloned;
    }

    /*  At the start of every loop we need to move some slides to the front of first slide.
        Some carousels breakout of container and needs slides before active slide all the time */
    if (this.slideShouldMoveToFront(slideIndex)) {
      steps -= this.itemCountCloned;
    }
    return steps * 100;
  }
  getLoopCount() {
    // 1 loop means when all the unique items has passed
    return this.itemCountUnique < 1 ? 0 : Math.floor(this.props.currentSlide / this.itemCountUnique);
  }
  isActiveSlideInFirstHalf() {
    return this.getLoopCount() % 2 === 0;
  }

  goToSlide = (index: number) => {
    if (!this.props.continuous) {
      index = Math.min(index, this.itemCount - this.props.slidesPerPage);
      index = Math.max(index, 0);
    }
    this.setState({
      currentSlide: index,
      translateX: this.getTranslateX(index),
    });
    this.props.onSlideChange && this.props.onSlideChange(getAbsoluteIndex(index, this.itemCount));
  };
  goBack = (steps: number) => {
    this.goToSlide(this.state.currentSlide - steps);
  };
  goForward = (steps: number) => {
    this.goToSlide(this.state.currentSlide + steps);
  };

  onSwiping = ({ deltaX, velocity }: { deltaX: number; velocity: number }) => {
    if (!!this.props.disabled || this.state.items.length <= 1) return;
    this.isResting = false;
    const { translateX } = this.state;
    const diff = deltaX - this.lastDeltaX;
    const dir = Math.sign(diff);
    const sameDir = dir === this.lastDir || dir === 0;
    if (dir !== 0) this.lastDir = dir;
    //If the user changes direction, use this point as the new starting point for calculating whether to trigger a slide change
    if (!sameDir) {
      this.offsetDeltaX = this.lastDeltaX;
    }
    this.lastDeltaX = deltaX;
    this.setState({
      translateX: translateX - diff,
    });
  };

  onSwiped = ({ deltaX, velocity }: { deltaX: number; velocity: number }) => {
    if (!!this.props.disabled || this.state.items.length <= 1) return;
    const currentDeltaX = deltaX - this.offsetDeltaX;
    //Trigger a slide change if swiped over the middle of the carousel, or swipe is fast or long enough:
    if (
      deltaX < -this.slideWidth / 2 ||
      (currentDeltaX < -SWIPE_THRESHOLD && velocity * velocity * currentDeltaX < -2 * SWIPE_THRESHOLD)
    ) {
      this.goBack(1);
    } else if (
      deltaX > this.slideWidth / 2 ||
      (currentDeltaX > SWIPE_THRESHOLD && velocity * velocity * currentDeltaX > 2 * SWIPE_THRESHOLD)
    ) {
      this.goForward(1);
    } else {
      this.setState({
        translateX: this.getTranslateX(this.state.currentSlide),
      });
    }
    this.lastDeltaX = 0;
    this.offsetDeltaX = 0;
  };

  getSpringConfig() {
    if (this.props.animationStyle === AnimationStyle.Gentle) {
      return {
        stiffness: 180,
        damping: 25,
        precision: 0.01,
      };
    }
    return presets.noWobble;
  }

  onRest = () => {
    this.isResting = true;
    this.props.onSwipeRest && this.props.onSwipeRest();
  };

  render() {
    const motionStyle: PlainStyleType<OpaqueConfig | number> = {
      translateX: this.isResting ? this.state.translateX : spring(this.state.translateX, this.getSpringConfig()),
    };

    return (
      <Swipeable
        innerRef={this.props.elementRef}
        onSwiping={this.onSwiping}
        onSwiped={this.onSwiped}
        delta={DELTA}
        className={this.props.className}
        trackTouch={!this.props.disabled && this.state.items.length > 1}
        style={{ height: this.props.height || 'auto' }}
      >
        <Motion style={motionStyle} onRest={this.onRest}>
          {(interpolatedStyle) => (
            <Container
              className={this.props.className}
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 0,
                padding: 0,
                listStyle: 'none',
                ...(this.isResting
                  ? { transform: `translateX(${this.state.translateX}px)` }
                  : interpolatedStyle.translateX !== 0
                  ? { transform: `translateX(${interpolatedStyle.translateX}px)` }
                  : null),
              }}
            >
              {React.Children.toArray(this.props.children).map((item, index) => {
                //const isActive = index % this.itemCountUnique === getAbsoluteIndex(this.state.currentSlide, this.itemCountUnique);
                return (
                  <div
                    key={index}
                    style={{
                      transform: `translateX(${this.getSlideTransformPercent(index)}%)`,
                      maxWidth: `${this.slideWidthPercent}%`,
                      height: this.props.height || 'auto',
                      backgroundColor: this.props.backgroundColor || '#ffffff',
                      flexBasis: `${this.slideWidthPercent}%`,
                      flexGrow: 0,
                      flexShrink: 0,
                      margin: 0,
                      padding: 0,
                    }}
                    onClick={() =>
                      this.props.onSlideClick && this.props.onSlideClick(getAbsoluteIndex(index, this.itemCountUnique))
                    }
                  >
                    {item}
                  </div>
                );
              })}
            </Container>
          )}
        </Motion>
      </Swipeable>
    );
  }
}

export default styled(applyClassName(Carousel));

export function getAbsoluteIndex(index: number, uniqueItemCount: number) {
  return index < 0 ? (uniqueItemCount + (index % uniqueItemCount)) % uniqueItemCount : index % uniqueItemCount;
}
