import React from 'react';
import { styled, StyledProps, StyledElementProps, applyClassName } from '@glitz/react';
import { ESC_KEY, Breakpoint } from '@avensia/scope';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import SlideIn from 'Shared/SlideIn';
import Close from 'Shared/Icon/Close';
import Back from 'Shared/Icon/Back';
import Overlay, { closeOpenOverlay } from 'Shared/Overlay';
import Viewport from 'Shared/Viewport';
import { ZIndex, large, depth, white, zeta, pixelsToUnit, transition } from 'Shared/Style';
import connect from 'Shared/connect';
import freezeScroll from 'Shared/freeze-scroll';

export enum CloseType {
  Close,
  Back,
}

type ConnectStateType = {
  currentBreakpoint: number;
};

type BasePropType = StyledProps & {
  isOpen: boolean;
  maxWidth: string;
  maxHeight: string;
  onCloseEnd?: () => void;
};

export type StrictPropType = StyledProps & {
  isOpen?: boolean;
  maxWidth?: string;
  maxHeight?: string;
};

export type PropType = StyledProps &
  StrictPropType &
  ConnectStateType & {
    title?: string;
    action?: React.StatelessComponent<any> | React.ComponentClass<any>;
    onClose: () => void;
    onCloseEnd?: () => void;
    closeType?: CloseType;
  };

const Transition: React.StatelessComponent<BasePropType & StyledElementProps> = ({
  className,
  isOpen,
  children,
  onCloseEnd,
}) => (
    <SlideIn isOpen={isOpen}>
      {(style: React.CSSProperties) => (
        <div className={className} style={style} onTransitionEnd={onCloseEnd}>
          {children}
        </div>
      )}
    </SlideIn>
  );

const HEADER_HEIGHT = 50;
const CompactBase = styled(applyClassName(Transition), {
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: 'white',
  zIndex: ZIndex.Panel,
  ...transition({
    property: ['opacity', 'transform'],
    duration: '300ms',
  }),
});

const ExtensiveBase = styled((props: BasePropType) => (
  <CompactBase
    css={{
      right: '0',
      bottom: '0',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
      maxWidth: props.maxWidth || '45rem',
      height: props.maxHeight ? '100%' : '60rem',
      maxHeight: props.maxHeight || `calc(80% - ${large})`,
      ...depth(),
    }}
    {...props}
  />
));

const Header = styled.header({
  backgroundColor: theme => theme.primaryColor,
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  color: white,
  paddingLeft: '20px',
  height: pixelsToUnit(HEADER_HEIGHT),
});

const Heading = styled.div({
  flexGrow: 1,
  fontSize: zeta,
  fontWeight: 'bold',
  textTransform: 'uppercase',
});

// Using flexbox here helps us growing children to
// full height in some cases
const Body = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  position: 'relative',
  overflowY: 'auto',
});

const ActionButton = styled(Button, {
  paddingRight: '20px',
});

const BackIcon = styled(Back, {
  width: pixelsToUnit(10.2),
  height: pixelsToUnit(17.6),
  verticalAlign: 'middle',
});

export const StrictPanel = (props: StrictPropType) => {
  // @ts-ignore: type 'typeof globalThis' has no index signature
  const isOpen = !!this.props.isOpen;
  const panelProps = {
    isOpen,
    maxWidth: props.maxWidth,
    maxHeight: props.maxHeight,
  };
  return (
    <Viewport>
      {(isCompact: boolean) =>
        isCompact ? (
          // @ts-ignore: type 'typeof globalThis' has no index signature
          <CompactBase {...panelProps}>{this.props.children}</CompactBase>
        ) : (
            <ExtensiveBase css={props.compose()} {...panelProps}>
              // @ts-ignore: type 'typeof globalThis' has no index signature
              {this.props.children}
            </ExtensiveBase>
          )
      }
    </Viewport>
  );
};

type StateType = {
  contentDisplay: boolean;
};
class Panel extends React.Component<PropType, StateType> {
  unfreezeScroll: () => void;
  state: StateType = {
    contentDisplay: false,
  };
  UNSAFE_componentWillMount() {
    if (this.props.isOpen) {
      closeOpenOverlay();
    }
  }
  componentDidMount() {
    window.addEventListener('keydown', this.keyDown);
  }
  componentWillUnmount() {
    this.scrollFreeze(false);
    window.removeEventListener('keydown', this.keyDown);
  }
  scrollFreeze(freeze: boolean) {
    if (this.unfreezeScroll) {
      this.unfreezeScroll();
    }
    this.unfreezeScroll = freeze ? freezeScroll() : null;
  }
  toggleContentDisplay = () => {
    this.setState({
      contentDisplay: this.props.isOpen,
    });
  };
  closeCompletely = () => {
    if (!this.props.isOpen) {
      this.toggleContentDisplay();
    }
    if (this.props.onCloseEnd) {
      this.props.onCloseEnd();
    }
  };
  componentDidUpdate(prevProps: PropType) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.currentBreakpoint < Breakpoint.Medium) {
        this.scrollFreeze(this.props.isOpen);
      }
      if (this.props.isOpen) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            this.toggleContentDisplay();
          });
        });
      }
    }
  }
  keyDown = (e: KeyboardEvent) => {
    if (e.keyCode === ESC_KEY && this.props.isOpen) {
      this.props.onClose();
    }
  };

  render() {
    const { contentDisplay } = this.state;
    const { maxWidth, maxHeight, isOpen } = this.props;
    const appendToDOM = isOpen ? isOpen : contentDisplay;
    const displayPanel = isOpen ? contentDisplay : isOpen;
    const panelProps = {
      isOpen: displayPanel,
      maxWidth,
      maxHeight,
      onCloseEnd: this.closeCompletely,
    };
    const panelHeader = this.props.title && (
      <Header>
        <Heading>{this.props.title}</Heading>
        <ActionButton onClick={this.props.onClose} variant={ButtonVariant.None} appearance={ButtonAppearance.Bare}>
          {this.props.closeType && this.props.closeType === CloseType.Back ? <BackIcon /> : <Close />}
        </ActionButton>
      </Header>
    );
    const panelBody = (
      <Body css={{ minHeight: this.props.title ? `calc(100% - ${HEADER_HEIGHT}px)` : '100%' }}>
        {this.props.children}
      </Body>
    );
    return (
      <Viewport>
        {(isCompact: boolean) =>
          appendToDOM &&
          (isCompact ? (
            <CompactBase css={this.props.compose()} {...panelProps}>
              {panelHeader}
              {panelBody}
            </CompactBase>
          ) : (
              <Overlay enabled={this.props.isOpen} onClose={this.props.onClose}>
                <ExtensiveBase css={this.props.compose()} {...panelProps}>
                  {panelHeader}
                  {panelBody}
                </ExtensiveBase>
              </Overlay>
            ))
        }
      </Viewport>
    );
  }
}

export default styled(
  connect(
    (state): ConnectStateType => ({
      currentBreakpoint: state.currentBreakpoint,
    }),
  )(Panel),
);
