import React from 'react';
import { Style } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import { transition, white, pixelsToUnit, minTinyMediaQuery } from 'Shared/Style';
import Button, { Variant as ButtonVariant, Appearance as ButtonAppearance } from 'Shared/Button';
import { Left as Prev, Right as Next } from 'Shared/Icon/Arrow';

export enum Direction {
  Next,
  Prev,
}

type PropType = {
  onClick: (direction: Direction) => void;
  direction: Direction;
  disabled: boolean;
  offset?: boolean;
};

export default styled((props: PropType & StyledProps) => {
  const { compose, disabled, offset, ...restProps } = props;
  const css: Style = {
    fontSize: pixelsToUnit(20),
    visibility: 'hidden',
    position: 'absolute',
    backgroundColor: white,
    borderRadius: '50%',
    padding: { xy: pixelsToUnit(5) },
    top: '50%',
    userSelect: 'none',
    ...transition({ property: 'opacity' }),
    ...(restProps.direction === Direction.Next
      ? {
          right: offset ? '-2.4rem' : 0,
        }
      : {
          left: offset ? '-2.4rem' : 0,
        }),
    [minTinyMediaQuery]: {
      visibility: 'visible',
    },
    opacity: 0.5,
    pointerEvents: 'auto',
    ...(!disabled && {
      cursor: 'pointer',
      ':hover': {
        opacity: 0.6,
      },
    }),
    ...(disabled && {
      cursor: 'none',
      opacity: 0.2,
      backgroundColor: 'transparent',
    }),
  };

  function handleClick() {
    restProps.onClick(restProps.direction);
  }

  return (
    <styled.Div css={compose(css)}>
      <Button
        variant={ButtonVariant.None}
        appearance={ButtonAppearance.Bare}
        onClick={handleClick}
        noRippleEffect
        css={{ backgroundColor: 'transparent', cursor: props.disabled ? 'default' : 'pointer' }}
      >
        {restProps.direction === Direction.Prev ? <Prev /> : <Next />}
      </Button>
    </styled.Div>
  );
});
