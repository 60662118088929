import EsalesProductForList from '../../../Esales/Models/EsalesProductForList.type';
import { epiPropertyValue } from '@avensia/scope-episerver';

export function getProduct(esalesProduct: EsalesProductForList) {
  // return only properties that'll be displayed
  return {
    brand: esalesProduct.brand,
    averageRating: esalesProduct.averageRating,
    ticket: esalesProduct.ticket,
    price: esalesProduct.price,
    displayName: epiPropertyValue(esalesProduct.variation.displayName),
    variantCode: epiPropertyValue(esalesProduct.variation.code),
    imageUrls: esalesProduct.imageUrls || [],
    isNew: esalesProduct.isNew,
    inStock: esalesProduct.inStock,
    hasPromotion: esalesProduct.hasPromotion,
    hasDiscount: esalesProduct.hasDiscount,
    url: esalesProduct.url,
    hasMultipleVariants: esalesProduct.hasMultipleVariants,
  };
}
