import React from 'react';
import { isIOS } from '@avensia/scope/device-type';

const DEFAULT_SCALE = 0.8;
const DEFAULT_ROTATE = 20;
const DEFAULT_OPACITY = 0;

type PropType = {
  isOpen: boolean;
  children?: (style: React.CSSProperties, onCloseEnd: () => void) => React.ReactElement<any>;
  scaleX?: boolean;
  onCloseEnd?: () => void;
};

export default function SlideIn({ isOpen, scaleX = true, children, onCloseEnd }: PropType) {
  return children(
    {
      transform: isIOS()
        ? `scale(${isOpen ? 1 : DEFAULT_SCALE})`
        : `perspective(40cm) scale(${isOpen ? 1 : DEFAULT_SCALE}) rotateX(${isOpen ? 0 : DEFAULT_ROTATE}deg)`,
      opacity: isOpen ? 1 : DEFAULT_OPACITY,
    },
    onCloseEnd,
  );
}
