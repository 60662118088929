import * as React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { thin, white, black, ZIndex } from 'Shared/Style';

type PropType = {
  visibleElement: HTMLElement;
} & StyledProps;

const Overlay = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: white,
  opacity: 0.5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: ZIndex.Overlay,
});

const Element = styled.div({
  backgroundColor: white,
  border: {
    xy: {
      style: 'solid',
      width: thin,
      color: black,
    },
  },
});

export default styled(
  class Cover extends React.Component<PropType> {
    elementContainerRef: HTMLDivElement;
    componentDidMount() {
      if (this.props.visibleElement) {
        this.elementContainerRef.appendChild(this.props.visibleElement);
      }
    }
    setCoverRef = (element: HTMLDivElement) => (this.elementContainerRef = element);
    render() {
      return (
        <Overlay css={this.props.compose()}>{this.props.visibleElement && <Element ref={this.setCoverRef} />}</Overlay>
      );
    }
  },
);
